$quote-inline-width: rem-calc(350);

.quote-inline-wrap-article-content {
  max-width: 100%;
  position: relative;
}

.quote-inline-wrap {
  float: left;
  max-width: $quote-inline-width;
  padding: 2rem 1rem 1rem 0;

  .quote-inline-marks {
    font-size: rem-calc(100);
    color: $dark-gray;
    line-height: 1rem;
  }

  .quote-inline-testimonial {
    border-radius: 5px;

    p {
      font-weight: 300;
    }
  }

  .quote-inline-person {
    margin-bottom: 1.5rem;

    .quote-inline-photo img {
      border-radius: 50%;
      width: rem-calc(70);
      height: rem-calc(70);
      float: left;
      margin-right: 1rem;
    }

    p {
      position: relative;
      top: 5px;

      &:nth-child(2) {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0;
      }

      &:nth-child(3) {
        font-size: 0.875rem;
        font-weight: 400;
        color: $dark-gray;
      }
    }
  }

  @include breakpoint(medium down) {
    width: 100%;
    float: none;
    display: block;
  }
}
