.icon-prism {
    width:70px;
    height: 65px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-bottom:$global-margin;

    &-01 {
        .cssmask & {
            mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTQ4LDhMNTksMjdMNCw4M0w0OCw4WiIvPgo8L3N2Zz4K');   
        }

        .no-cssmask & {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTQ4LDhMNTksMjdMNCw4M0w0OCw4WiIvPgo8L3N2Zz4K');   
            
        }
    }
    
    &-02 {

        .cssmask & {
    
            mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTYsODJMNTksMjdMNzAsNDZMNiw4MloiLz4KPC9zdmc+Cg==');        
    
        }

        .no-cssmask & {
            
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTYsODJMNTksMjdMNzAsNDZMNiw4MloiLz4KPC9zdmc+Cg==');        
            
        }
    }

    &-03 {
        .cssmask & {
            mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTYsODJMNzAsNDZMODEsNjVMNiw4MloiLz4KPC9zdmc+Cg==');        
        }

        .no-cssmask & {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTYsODJMNzAsNDZMODEsNjVMNiw4MloiLz4KPC9zdmc+Cg==');        
        }


        
    }

    &-04 {
        .cssmask & {
            mask-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTUsODJMOTEsODJMODIsNjVMNSw4MloiLz4KPC9zdmc+Cg==');        
        }

        .no-cssmask & {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDg4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjYzLjAxMywyODMuMTU1TDU2OS40MywyODMuMTU1TDYxNS41MDIsMjAxLjgxTDY2My4wMTMsMjgzLjE1NVpNNTc2LjI5MywyNzkuMTU1TDY1Ni4wNDQsMjc5LjE1NUw2MTUuNTU1LDIwOS44MzNMNTc2LjI5MywyNzkuMTU1WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNTc1Ljg2MiwyNzkuMTU1TDYyNy43MjIsMjI0Ljk4NSIgc3R5bGU9ImZpbGw6d2hpdGU7ZmlsbC1ydWxlOm5vbnplcm87Ii8+CiAgICAgICAgPHBhdGggZD0iTTYyOS4xNjYsMjI2LjM2OUw1NzcuMzA2LDI4MC41MzhMNTc0LjQxNywyNzcuNzcxTDYyNi4yNzcsMjIzLjYwMkw2MjkuMTY2LDIyNi4zNjlaIi8+CiAgICA8L2c+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC01NjgsLTE5OSkiPgogICAgICAgIDxwYXRoIGQ9Ik02MzcuOTIxLDI0Ni44ODRMNTc1Ljg2NSwyODIuODg0TDU3My44NTgsMjc5LjQyNUw2MzUuOTE0LDI0My40MjVMNjM3LjkyMSwyNDYuODg0WiIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwtNTY4LC0xOTkpIj4KICAgICAgICA8cGF0aCBkPSJNNjQ5LjU3NiwyNjUuNTYzTDU3NS4zMjEsMjgzLjEwMUw1NzQuNDAyLDI3OS4yMDhMNjQ4LjY1NywyNjEuNjdMNjQ5LjU3NiwyNjUuNTYzWiIvPgogICAgPC9nPgogICAgPHBhdGggZD0iTTUsODJMOTEsODJMODIsNjVMNSw4MloiLz4KPC9zdmc+Cg==');        
        }
    }
    
}