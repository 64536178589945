$hero-height: calc(100vh - 166px) ;

.hero-section {
  background-size: cover;
  height: $hero-height;
  min-height:500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  overflow: hidden;
  @media (max-width: 650px) {
    height: calc(100vh - 116px);
  }

  .hero-section-text {
    color: #fff;
    //text-shadow: 1px 1px 2px $dark-gray;
    font-style: italic;
    position: relative;
  }

  svg {
    opacity: 0.1;
    position: absolute;
    height: 50%;
    width: 50%;
    @media (max-width: 650px) {
      height: 80%;
      width: 80%;
    }
    path {
      fill: white;
    }
  }

  .btn {
    position: relative;
    z-index: 9999;
  }
}
