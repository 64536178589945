$offcanvas-full-screen-bg: $button-background;
$offcanvas-full-screen-side: right;
$offcanvas-full-screen-closebutton-size: 5rem;
$offcanvas-background: $button-background;


.offcanvas-full-screen {
  @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
  @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: 100%);

}

.off-canvas {
  background: $button-background;
}

.offcanvas-full-screen-inner {
  padding: 1rem;
  text-align: center;
}

.offcanvas-full-screen-menu {
  @include menu-base;
  @include menu-direction(vertical);

  a {
    color: $white;

    &:hover {
      color: darken($white, 30%);
    }
  }
}

.offcanvas-full-screen-close {
  color: $white;
  font-size: $offcanvas-full-screen-closebutton-size;

  &:hover {
    color: darken($white, 30%);
  }
}
